import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation()

  useEffect(_ => {
    window.scrollTo({ top: 0, behavior: 'instant' })
  }, [pathname])

  return children || null
}

export default ScrollToTop
